import CombinedPage from "../components/UserDashboard";

const UserDashboard = () => {
  return (
    <>
      <CombinedPage />
    </>
  );
};

export default UserDashboard;
