import Landing from "../components/Landing";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <>
      <Landing />
      <Footer />
    </>
  );
};

export default Home;
