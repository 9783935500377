import RestaurantDashboard from "../components/RestaurantDashboard";

const RestaurantDashboardPage = () => {
  return (
    <div>
      <RestaurantDashboard />
    </div>
  );
}

export default RestaurantDashboardPage;